<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
    />
    <login-modal
      v-if="showLoginModal"
      @close="showLoginModal= false"
      @action="$router.replace('/admin-config')"
    />
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/config')"
          >
          <span class="robot-setting-title">&nbsp;&nbsp;&nbsp;로봇설정</span>
          <v-spacer />
          <span
            class="robot-setting-title"
            type="button"
            @click="save"
          >저장&nbsp;</span>
        </v-row>
        <v-row class="content-area">
          <v-col cols="12 pa-0">
            <h3 class="robot-option-title">
              기본 속도 설정
            </h3>
            <input
              v-model="speed"
              type="number"
              min="0.1"
              class="input-setting"
              placeholder="m/s"
            >
            <p class="default-robot-option-text">
              최저 속도: 0.1 <span>(10cm/s)</span> &nbsp;&nbsp; / &nbsp;&nbsp; 최대 속도: 1.5 <span>(150cm/s)</span>
            </p>
          </v-col>
          <v-col cols="12 pa-0">
            <h3 class="robot-option-title">
              자동 충전 배터리 설정
            </h3>
            <input
              v-model="minBattery"
              type="number"
              min="5"
              max="100"
              class="input-setting"
              placeholder="%"
            >
            <p class="default-robot-option-text">
              최소 자동 충전 잔량: 5 <span>(5%)</span>
            </p>
          </v-col>
          <v-col cols="12 pa-0">
            <h3 class="robot-option-title">
              자동 수행 배터리 설정
            </h3>
            <input
              v-model="maxBattery"
              type="number"
              min="80"
              max="100"
              class="input-setting"
              placeholder="%"
            >
            <p class="default-robot-option-text">
              최소 자동 수행 잔량: {{ Number(minBattery) + 1 > 100 ? 100 : Number(minBattery) + 1 }} <span>({{ Number(minBattery) + 1 > 100 ? 100 : Number(minBattery) + 1 }}%)</span>
            </p>
          </v-col>
          <v-col cols="12 pa-0">
            <v-btn
              class="btn mt-9"
              color="#00BFB4"
              dark
              @click="powerOff"
            >
              로봇 PC 안전 종료
            </v-btn>
            &nbsp;
            <v-btn
              class="btn mt-9"
              color="#00BFB4"
              dark
              @click="reboot"
            >
              로봇 PC 재가동
            </v-btn>
            &nbsp;
            <v-btn
              class="btn mt-9"
              color="#00BFB4"
              dark
              @click="showLoginModal= true"
            >
              관리자 설정
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import LoginModal from '@/components/modals/loginModal.vue';
import DefaultHeader from '@/components/header/defaultHeader.vue';

export default {
  name: 'RobotSetting',
  components: {
    LoginModal,
    DefaultModal,
    DefaultHeader,
  },
  data() {
    return {
      showModal: false,
      showLoginModal: false,
      modalState: {
        message: null,
        action: null
      },
      speed: null,
      minBattery: null,
      maxBattery: null
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.speed = this.$store.state.base_speed;
      this.minBattery = this.$store.state.docking_battery;
      this.maxBattery = this.$store.state.docking_battery_max;
    },
    powerOff() {
      this.$axios.put('/powerOff');
    },
    reboot() {
      this.$axios.put('/reboot');
    },
    save() {
      if (this.speed && this.minBattery && this.maxBattery) {
        this.speed = Number(this.speed);
        this.minBattery = Number(this.minBattery);
        this.maxBattery = Number(this.maxBattery);

        if (this.speed < 0.1 || this.speed > 1.5) {
          this.$toast('기본 속도는 0.1과 1.5사이입니다');
          return;
        }
        if (this.minBattery < 5 || this.minBattery > 99) {
          this.$toast('충전 잔량은 5와 99사이입니다');
          return;
        }
        if (this.maxBattery < 6 || this.maxBattery > 100) {
          this.$toast('수행 잔량은 6과 100사이입니다');
          return;
        }
        if (this.minBattery >= this.maxBattery) {
          this.$toast('자동 수행 잔량이 충전 잔량보다 많아야 합니다');
          return;
        }

        this.openModal();
        this.fetchSettings();
      } else {
        this.$toast('속도와 배터리 설정 모두 입력해 주세요');
      }
    },
    openModal() {
      this.modalState.message = '설정을 저장 중입니다';
      this.modalState.action = 'no-action';
      this.showModal = true;
    },
    fetchSettings() {
      return this.fetchSetSpeed()
        .then(this.fetchSetMinBattery)
        .then(this.fetchSetMaxBattery)
        .then(() => {
          this.$router.replace('/config');
        })
        .catch(err => {
          this.showModal = false;
          throw Error(err);
        });
    },
    fetchSetSpeed() {
      return this.$axios.put(`/setspeed${this.speed}`, {
        speed: this.speed
      });
    },
    fetchSetMinBattery() {
      return this.$axios.put(`/setbattery${this.minBattery}`, {
        battery: this.minBattery
      });
    },
    fetchSetMaxBattery() {
      return this.$axios.put(`/setmaxbattery${this.maxBattery}`, {
        battery: this.maxBattery
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.robot-setting-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
}

.content-area {
  height: 60vh;
  text-align: center;
  overflow: auto;
}

.robot-option-title {
  font-size: 2rem;
  color: white;
}

.input-setting {
  width: 70%;
  height: 80px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-setting:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.default-robot-option-text {
  display: -webkit-inline-box;
  margin: 0;
  width: 70%;
  font-size: 1.5rem;
  color: white;
}

.default-robot-option-text > span {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .robot-setting-title {
    font-size: 20px !important;
  }

  .input-setting {
    width: 70%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .default-robot-option-text {
    display: -webkit-inline-box;
    margin: 0;
    width: 70%;
    font-size: 1.0rem;
    color: white;
  }

  .default-robot-option-text > span {
    font-size: 0.7rem;
  }

}

</style>
